<template>
  <div
    class="uk-inline uk-border-radius"
    :class="itemType == 'course' ? 'searchItem' : ''"
    style="height: 280px"
    :href="'#modal-courses-1'"
    @click="selectCourseModalMethod(item.id)"
  >
    <img :src="getBased(item.thumbnail)" alt="" />
    <div class="uk-overlay uk-overlay-primary uk-position-bottom">
      <p class="text-center">{{ item.ar_title }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SearchItem",

  props: {
    item: Object,
    itemType: String
  },

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"]),
    selectCourseModalMethod(id) {
      if (this.itemType == "course") {
        UIkit.modal("#modal-courses-1").toggle();
        return this.selectCourseModal(id);
      }
    }
  }
};
</script>

<style scoped>
.searchItem {
  cursor: pointer;
}
.ECourses-section .uk-border-radius .uk-overlay-primary,
.ACourses-section .uk-border-radius .uk-overlay-primary {
  border-radius: 30px !important;
}
</style>
