<template>
  <div class="search ECourses-section px-10">
    <h1 class="text-center font-weight-bold">{{ $t("search.results") }}</h1>
    <!-- categories -->
    <h2 class="font-weight-bold">{{ $t("search.categories") }}</h2>
    <h2 v-show="!Object.keys(categories).length">
      {{ $t("search.noResults") }}
    </h2>
    <v-row class="row">
      <v-col
        cols="12"
        md="4"
        sm="6"
        v-for="Category in categories"
        :key="Category.id"
      >
        <search-item :item="Category" itemType="category"></search-item>
      </v-col>
    </v-row>
    <!-- training paths -->
    <h2 class="font-weight-bold">{{ $t("search.trainingPaths") }}</h2>
    <h2 v-show="!Object.keys(trainingPaths).length">
      {{ $t("search.noResults") }}
    </h2>
    <v-row class="row">
      <v-col
        cols="12"
        md="4"
        sm="6"
        v-for="trainingPath in trainingPaths"
        :key="trainingPath.id"
      >
        <search-item :item="trainingPath" itemType="trainingPath"></search-item>
      </v-col>
    </v-row>
    <!-- courses -->
    <h2 class="font-weight-bold">{{ $t("search.courses") }}</h2>
    <h2 v-show="!Object.keys(courses).length">{{ $t("search.noResults") }}</h2>
    <v-row class="row">
      <v-col cols="12" md="4" sm="6" v-for="course in courses" :key="course.id">
        <search-item :item="course" itemType="course"></search-item>
      </v-col>
    </v-row>
    <course-info-modal></course-info-modal>
  </div>
</template>

<script>
import SearchItem from "./components/SearchItem";
import CourseInfoModal from "../../core/CourseInfoModal/CourseInfoModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "Search",

  components: {
    SearchItem,
    CourseInfoModal
  },

  created() {
    this.fetchSearch(this.$route.query.q);
  },

  watch: {
    $route: "updateSearch"
  },

  methods: {
    ...mapActions("Search", ["fetchSearch"]),
    updateSearch: function() {
      this.fetchSearch(this.$route.query.q);
    }
  },

  computed: {
    ...mapState("Search", ["categories", "trainingPaths", "courses"])
  }
};
</script>

<style lang="scss">
.search {
  h1,
  h2 {
    padding: 20px;
    color: teal;
  }
}
</style>
